import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Component
import EnLayout from '../../components/EnLayout';

// Images
import Gallery from '../../img/gallery.gif';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('en');

const Case = () => {
  const [query, setQuery] = useState('');

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/src/markdown/case-en/**.md" } }) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail {
                publicURL
              }
              caseCategory
            }
            excerpt
          }
        }
        group(field: frontmatter___caseCategory) {
          fieldValue
        }
      }
    }
  `);

  const filteredCase = data.allMarkdownRemark.edges.filter((edge) => {
    if (query) return edge.node.frontmatter.caseCategory === query;
    return null;
  });
  const cases = query ? filteredCase : data.allMarkdownRemark.edges;

  return (
    <EnLayout>
      <section id="page_bn" className="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
        <div className="container">
          <div id="breadcrumb" className="">
            <div className="container radius" style={{ backgroundColor: '#7aacd2' }}>
              <div className="col-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb small text-white pt-3 pb-2 bg-js">
                    <li className="breadcrumb-item">
                      <Link to="/en" style={{ color: '#fff' }}>
                        {LANG.首頁}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">{LANG.成功案例}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="case" className="my-3">
        <div className="container ">
          <div className="row">
            <div className="col-12 text-left">
              <p className="h3">
                <strong>{LANG.成功案例}</strong>
              </p>
              <br />
              {LANG.成功案例內文}
            </div>
            <div id="" className="col-12">
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section className="container mb-4">
        <div className="portfolio-div">
          <div className="portfolio">
            <div className="row col-12 my-4">
              <div className="categories-grid wow fadeInLeft">
                <nav className="categories text-center">
                  <ul className="portfolio_filter m-0">
                    <li style={{ cursor: 'pointer' }}>
                      <a
                        className={query === '' ? 'active' : null}
                        onClick={() => setQuery('')}
                        style={{ cursor: 'pointer' }}
                      >
                        All
                      </a>
                    </li>
                    {data.allMarkdownRemark.group.map(({ fieldValue }) => {
                      return (
                        <li key={fieldValue} style={{ cursor: 'pointer' }}>
                          <a onClick={() => setQuery(fieldValue)} className={fieldValue === query ? 'active' : null}>
                            {LANG[fieldValue]}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="portfolio_container row">
              {cases &&
                cases.map(
                  ({
                    node: {
                      fields: { slug },
                      frontmatter: {
                        thumbnail: { publicURL },
                        title,
                      },
                      excerpt,
                    },
                  }) => {
                    return (
                      <div className="col-6 col-md-4 col-lg-3 mb-3 spc" key={slug}>
                        <Link to={`/en/case/${slug}`}>
                          <div className="card text-secondary">
                            <div className="hover-zoom rounded-top" style={{ backgroundImage: `url(${publicURL})` }}>
                              <img src={Gallery} className="card-img-top" />
                            </div>
                            <div className="card-body">
                              <h5 className="card-title text_limit1" style={{ color: 'rgba(108,117,125)' }}>
                                {title}
                              </h5>
                              <p className="card-text text_limit2">{excerpt}</p>
                              <span className="btn-sm btn-primary">{LANG.了解案例}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </section>
    </EnLayout>
  );
};

export default Case;
